import React from 'react';

const AZ3 = (props) => {
  const {bg, color1, color2} = props;
  return (
    <svg {...props} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-40 0 500 460">
      <path d="M368.71,277.36a4.11,4.11,0,0,0,1.74,2.16l4.59-6.43A13.9,13.9,0,0,0,368.71,277.36Z" fill={bg} />
      <path d="M311.44,99.52c2.24,1.35,4.41,2.79,6.52,4.3L322,98.2a107.19,107.19,0,0,0-19.5-11.08l-4.13,5.76c2.39,1,4.74,2.09,7,3.25-7.13,10.47-18.32,20.51-29.47,28.06l-10.06,14C283.33,129.63,301.56,114.25,311.44,99.52Z" fill={bg} />
      <path d="M158.88,224.42h-6.23Q156.7,226,158.88,224.42Z" fill={bg} />
      <path d="M364,390.09V291.61h0a55.67,55.67,0,0,1-8.73-.56,14.43,14.43,0,0,0-2.85.81,19.86,19.86,0,0,0-5,3v95.24Z" fill={bg} />
      <path d="M305,212.59c-.68.9-1.42,1.74-2.17,2.56a1.32,1.32,0,0,0,1,.1l6.65-2.13-3.78-2.8Z" fill={bg} />
      <path d="M249.24,332a26.56,26.56,0,0,1,3.73-6c.69-.9,1.56-2,1.51-2.33-3.26-2.85-6.5,1.66-9.24,3.33-.47.29-1,.61-1.44.94-2.31,1.55-5.46,1.23-8.3-.41-.15-.08-.32-.13-.4-.29a.57.57,0,0,1,0-.51l.16-.34a65.33,65.33,0,0,0,3.36-7.84c.84-2.62.52-5.43.54-8.13,0-1.08-.56-1-1.43-.88a24.5,24.5,0,0,1-3,.09c-1.25,0-2.51-.11-3.76-.11-1.85-.06-3.71,0-5.55-.11h-.05V332Z" fill={bg} />
      <path d="M375,117.27l-.68,3.47-.42,2.22-.27,1.4c0,.09-.11.31-.08.4.09.28.84.52,1.11.65a28,28,0,0,1,4.11-2.2c1.18-.49,2.41-1,3.41-1.51l-6.42-9C375.57,114.27,375.31,115.77,375,117.27Z" fill={bg} />
      <path d="M146.26,145.08a31.62,31.62,0,0,0,5.3,8c2.89,3,7.55,2.78,7.55,2.78s-3.49-16.76-6.27-23.73v12.9Z" fill={bg} />
      <path d="M319.87,164.23l-1.27-1.71a49.88,49.88,0,0,0-29.19-19l3.48-4.83a57.26,57.26,0,0,1-27.77.54l-1.53,2.14v-9.82a73.91,73.91,0,0,1-9.33,4.06,57.79,57.79,0,0,1-13.19-8.26v31.54a49.72,49.72,0,0,0-12.37,41.41c.19.73.39,1.46.59,2.18a11.6,11.6,0,0,0,7.32,7.85c.65.23,1.28.52,1.91.8,1.76.76,3.53,1.53,5.33,2.19a23.42,23.42,0,0,0,2.73.87c1,.24,2.18.62,3.12.06a3.81,3.81,0,0,0,.71-.62c.41-.42.82-.85,1.19-1.31a33.24,33.24,0,0,1,26.79-53.05,32.68,32.68,0,0,1,26.54,13.32l1.71,2.31,13.23-9.69V210a11.57,11.57,0,0,0,6.68-7.57,177.11,177.11,0,0,0,5.56-27.28c1-8.87,1.41-20.42-1.41-30.11H319.87Z" fill={bg} />
      <path d="M111.71,245.8a74.87,74.87,0,0,0-2,15.93c14.21.95,32.4,7.62,45.1,16.49A45,45,0,0,0,111.71,245.8Z" fill={bg} />
      <path d="M106.1,246.12a45,45,0,0,0-21,8.28c-2.38,12.48-.46,34.8,6.78,49.8a89.65,89.65,0,0,0,16.63-14C103.87,275,103.06,260.16,106.1,246.12Z" fill={bg} />
      <polygon points="111.22 245.18 111.22 245.18 111.22 245.18 111.22 245.18" fill={bg} />
      <path d="M136.87,182.41A31.19,31.19,0,0,0,143,187.5c.62.42,1.21.83,1.78,1.24A24.59,24.59,0,0,0,152,192c.61.2,1.21.4,1.8.61s1.37.49,2,.74c.92.34,1.82.66,2.71,1h1.14V184.24a53.6,53.6,0,0,0-5.15-7h-21A32.27,32.27,0,0,0,136.87,182.41Z" fill={bg} />
      <path d="M72.19,169.46a7.64,7.64,0,0,0-7.41-7.54H46.57v14.94H64.89A7.5,7.5,0,0,0,72.19,169.46Z" fill={color1} />
      <path d="M46.57,223.68v.87H71.5a15.63,15.63,0,0,0,15-15.55,15.34,15.34,0,0,0-15.07-15.29H46.57v12.64l4,3.24c1.45,1.16,3,2.41,3.58,4.16C55.69,218.05,51.46,221.08,46.57,223.68Z" fill={color1} />
      <path d="M95.78,359.28a8.21,8.21,0,0,0-8-8.11H67.57v16.08H87.94A8.07,8.07,0,0,0,95.78,359.28Z" fill={color1} />
      <path d="M95.42,387.29H67.57v0q22.2,9.3,44.46,18.42c0-.51.08-1,.08-1.54A17,17,0,0,0,95.42,387.29Z" fill={color1} />
      <path d="M149.15,407.15,144.63,419q25.05,10.11,50.19,20l-12.18-31.9Z" fill={color1} />
      <path d="M148.12,255.23c-.55-1.12-1.11-2.28-1.76-3.43-.27-.48-.56-1-.87-1.51a60.25,60.25,0,0,1-4.13-8.11.57.57,0,0,1,.16-.64.53.53,0,0,1,.66,0c1.24.86,2.45,1.83,3.62,2.77a31.46,31.46,0,0,0,5.07,3.58,23.84,23.84,0,0,0,5.26,1.82v-8.29H102.57V217.28a32.78,32.78,0,0,1-31,24.11h-42V239.2c-1.93,1.57-3.31,3-1.65,6.62.32.7.63,1.43,0,2-2.35,2.2-.94,4.63-.68,7.08.46,4.35-.63,6.53-1.85,10.66-.05.19-.09.4-.13.59l15.19,39.54L64.18,244H81.76l-9.63,25a45.12,45.12,0,0,1,6.8-9.23c-1.3,15.39,2,34.43,8.29,47.18a74.52,74.52,0,0,1-14.81,6.22,45,45,0,0,1-5.93-22.33,45.67,45.67,0,0,1,1.15-10.16L48.22,331.13H88a26.93,26.93,0,0,1,4,.3A45.38,45.38,0,0,1,75.5,317.88c13.67-4.36,26.1-12.45,37-24a89.83,89.83,0,0,1,20.48,7.38c-9.23,13.56-27.18,26.15-39.21,30.52a28.34,28.34,0,0,1,7.45,2.73c13.91-6.58,28.6-18.86,36.48-30.6a74.72,74.72,0,0,1,12.78,9.71,44.94,44.94,0,0,1-48.59,21.27,27.17,27.17,0,0,1,6,4.64,28,28,0,0,1,5.39,32.25,34.3,34.3,0,0,1,8.33,6.32,36,36,0,0,1,10.25,19.32L156.13,332V297.41A44.57,44.57,0,0,1,153,308.56c-10.61-9.66-23.84-16.37-39.32-20a89.39,89.39,0,0,1-3.87-21.43c16.51,1.22,36.61,10.61,46.34,18.9V264.8a28.9,28.9,0,0,1-4.64-4A22.27,22.27,0,0,1,148.12,255.23Zm-36.9-10Zm-19.35,59c-7.24-15-9.16-37.32-6.78-49.8a45,45,0,0,1,21-8.28c-3,14-2.23,28.85,2.4,44.05A89.65,89.65,0,0,1,91.87,304.2Zm17.83-42.47a74.87,74.87,0,0,1,2-15.93,45,45,0,0,1,43.09,32.42C142.1,269.35,123.91,262.68,109.7,261.73Z" fill={color1} />
      <path d="M359.08,71s10,10.22,14.65,15.67c2.61,3.08,3,7.22,3.14,11.13a82.57,82.57,0,0,1-1.07,15l6.42,9,.57-.29.13-.06c.74-.39,1.47-.73,2.18-1,2.81-1.31,5-2.35,6.63-5.55,1.06-2.13,1.47-5.24,2-9.53l.25-1.87c.09-.67.19-1.39.3-2.14.29-2,.61-4.28.85-6.71q0-5.16,0-10.32,0-24,.06-48c0-9.33-.31-26.2-.14-35.52h-3.3L348.2,61.56a21.39,21.39,0,0,1,5.07,3.69Z" fill={color1} />
      <path d="M343.63,67.94l2.54,3.54c-.22-2-.49-4-.77-6Z" fill={color1} />
      <path d="M382.35,271.22a35.91,35.91,0,0,0-5.69,1.28c-.55.19-1.09.38-1.62.59l-4.59,6.43q2.74,1.73,9.09.76l1.09-.17a57.56,57.56,0,0,0,11.17-2.53.55.55,0,0,1,.66.25.57.57,0,0,1-.13.71,90,90,0,0,1-7.65,6A41.25,41.25,0,0,1,377,288.6l-1.39.61a25.27,25.27,0,0,1-9.83,2.37,2.28,2.28,0,0,0-.36,0,.53.53,0,0,1-.6.22l-.79-.22v98.48H347.46V294.85a52.49,52.49,0,0,0-4.85,4.52l-.25.26c.57,1.63,1.71,4.47,4.2,4.23a.54.54,0,0,1,.57.39.57.57,0,0,1-.24.66,30.91,30.91,0,0,1-11.31,4c-1.71.3-3.45.38-5.18.51s-3.7,0-5.55.11l-2.37-.24c-1.69-.49-3.34-1-4.94-1.4.24,1.65.35,3.4.44,5.23,0,.72,0,1.69,0,2.65h9.65V439.41h67c0-3.77,0-7.54,0-11.3q0-24.92.08-49.84,0-24.3,0-48.6,0-24.76.05-49.53,0-6.27,0-12.52a42.73,42.73,0,0,1-8.6,2.84C385,270.77,383.64,271,382.35,271.22Z" fill={color1} />
      <path d="M394.93,250.72c0-1.85,0-3.69,0-5.53l-4.2,5.89A19,19,0,0,0,394.93,250.72Z" fill={color1} />
      <path d="M137.26,215.72a.58.58,0,0,1,.15-.71.54.54,0,0,1,.7,0,58.84,58.84,0,0,0,9.39,6.68l1,.59a26.15,26.15,0,0,0,4.18,2.12h6.23a4.37,4.37,0,0,0,.69-.61c-1-3.31-3.19-5.48-5.57-7.59a36.59,36.59,0,0,0-4.8-3.4c-1.11-.7-2.25-1.43-3.38-2.24a41.34,41.34,0,0,1-7.72-6.89,32,32,0,0,1-5.43-9c0-.1-.06-.21-.1-.31H119.79v30.07h22.48l-.13-.19A94.77,94.77,0,0,1,137.26,215.72Z" fill={color1} />
      <path d="M158.58,194.35a29.4,29.4,0,0,0,4.73,1.25c.68.11,1.35.25,2,.39a65.11,65.11,0,0,0-5.61-11.75v10.11Z" fill={color1} />
      <path d="M216.21,117.1a48.49,48.49,0,0,0,6.24,9.13l1,1.23c2.17,2.68,4,5,6.47,6.52a.57.57,0,0,1,.26.38.65.65,0,0,1-.11.45c-8,10.46-7.77,28-6.35,40.38a173.56,173.56,0,0,0,5,25.1,49.72,49.72,0,0,1,12.37-41.41V127.34a58.42,58.42,0,0,1-9.41-9.83q4.78-1.53,9.41-3.53v-7.61a95.57,95.57,0,0,1-13.37,5.12,57.68,57.68,0,0,1-7.62-28.66,59,59,0,0,1,1.2-11.78H209.71c0,.41-.1.82-.14,1.23C208,87.23,209.4,103.49,216.21,117.1Z" fill={color1} />
      <path d="M181.13,125.41c.27-.13,1-.37,1.12-.65,0-.09-.06-.31-.08-.4l-.27-1.4c-.14-.74-.28-1.48-.43-2.22l-.67-3.47A92.41,92.41,0,0,1,179,97.78c.12-3.91.53-8,3.14-11.13,4.41-5.19,13.69-14.69,14.58-15.6H175.35v51.44c.55.25,1.12.49,1.68.72A27.37,27.37,0,0,1,181.13,125.41Z" fill={color1} />
      <path d="M130.56,172.92a.57.57,0,0,1,.62.23c.83,1.28,1.59,2.64,2.32,4l.08.13h21c-.5-.59-1-1.17-1.56-1.75-4-4.32-8.49-8.62-11.52-13.7h-21.7v15.45h10.56c-.11-1.25-.19-2.52-.18-3.79A.56.56,0,0,1,130.56,172.92Z" fill={color1} />
      <path d="M241.07,71.05h-3.88a131.68,131.68,0,0,0,3.88,17.69Z" fill={color1} />
      <polygon points="156.82 387.59 174.99 387.59 165.95 363.93 156.82 387.59" fill={color1} />
      <polygon points="364.49 0.59 295.49 0.42 330.05 48.66 364.49 0.59" fill={color1} />
      <path d="M141.36,135.76a.56.56,0,0,1,.51.36,50.31,50.31,0,0,0,3.12,6.54c.44.83.87,1.63,1.27,2.42h6.58v-12.9c-.05-.11-.09-.23-.14-.34-2.59-6.24-3.63-12.6-2.26-19.27a40.18,40.18,0,0,1,2.4-7.3V42.19a35.52,35.52,0,0,1-34.7,27.86H89.84v72.33H67.52V57.91a14.21,14.21,0,0,1-.59,3c-.65,1.67-2,3-2.83,4.54-1,1.94-1.27,4.21-2.16,6.21-2,4.55-8.56,4.84-11.25.69C49.2,70.11,49,66.75,46.5,65.58c-2.88-1.37-8.28.17-11.73-.49-4.58-.88-8.08-1.73-11.78,2a3.57,3.57,0,0,0-.4.46c-2.18,3.14-2.07,8.83-.86,12.3,1.76,5.07,2.63,5.54.9,11.21-1.57,5.15,2.16,10.42,2.21,15.79.06,6-1,10.31.53,16.47,1.15,4.58,3,9.4,3.14,14.15a10.57,10.57,0,0,1-2.23,7.21,11.38,11.38,0,0,0-1.82,2.24A6.57,6.57,0,0,0,24,149.2c-.49,6.68-1.22,13.64,1,20.12a26.48,26.48,0,0,0,4.5,7.7V145.08H64.91a24.41,24.41,0,0,1,21.71,35.51,29.77,29.77,0,0,1,7.59,5.69,31.19,31.19,0,0,1,8.36,14.34V145.08H138a50.55,50.55,0,0,1,2.85-9A.54.54,0,0,1,141.36,135.76Z" fill={color1} />
      <path d="M176.3,280.28c6.17.94,9.81,0,10.83-2.92-2.12-2.69-5-3.84-7.95-4.86a30.37,30.37,0,0,0-4.76-1.12V280l.78.12Z" fill={color1} />
      <path d="M174.42,248.5v2l.83,0C175,249.76,174.7,249.11,174.42,248.5Z" fill={color1} />
      <path d="M307.57,336.59V332h-1.38A19.35,19.35,0,0,1,307,334,17.76,17.76,0,0,1,307.57,336.59Z" fill={color1} />
      <path d="M303.33,352c-.53.78-2.73,4.19-4.09,3.81a8.58,8.58,0,0,1-.42-1.14,8,8,0,0,0-1.3-2.91,2.75,2.75,0,0,0-2.63-1.21c-.2,0-1.94.12-2-.09.24,1,.59,2.2.89,3.26a19.27,19.27,0,0,1,.74,2.88,20.77,20.77,0,0,1-2.07,10.34c-.21.41-.43.81-.67,1.2s-.43.67-.66,1a16,16,0,0,1-3,3.11c-1.51,1.19-3.39,1.76-5,2.87-.57.4-1.06.9-1.66,1.25-.17.1-1.59.71-1.59.71-.17-.59.89-2.42,1.11-3a36.77,36.77,0,0,0,1.58-5.14c.82-4,.37-5-1.37-6.17-1.5-1-5.3-.44-7.26,1.6-1.17,1.21-1.78,3.34-.78,7.11-4.48-3-8-6.92-9-10.17-1.23-3.94-.63-7.83,0-11.33a12.33,12.33,0,0,1-2.21.47c-2.37.36-4.61.7-5.54,4.38a.54.54,0,0,1-.41.41.52.52,0,0,1-.54-.19l-.84-1c-2.53-3.11-4.37-5.35-5.37-8.09a19.9,19.9,0,0,1-.73-12.07,14.84,14.84,0,0,1,.63-1.77H225.39V309.46c-1.71-.13-3.43-.2-5.13-.5a30.91,30.91,0,0,1-11.31-4,.57.57,0,0,1-.24-.66.53.53,0,0,1,.57-.39c2.49.24,3.63-2.6,4.2-4.23l-.25-.26c-2.58-2.63-5.79-5.9-9.82-7.51a14.86,14.86,0,0,0-2.81-.81,56.7,56.7,0,0,1-8.77.56c-.27.07-.53.13-.8.22a.53.53,0,0,1-.6-.22,2.4,2.4,0,0,0-.37,0,25.27,25.27,0,0,1-9.82-2.37l-1.39-.61c-1.38-.6-2.87-1.25-4.43-2.08v29.42h44.3V332H174.83l45,116.86,3.37,1.32,1.45.57V342.23h20.11v97.18H299.4V459h8.17V342a18.66,18.66,0,0,1-1.08,3.94A32.84,32.84,0,0,1,303.33,352Z" fill={color1} />
      <path d="M32.61,331.06,14.48,284.12c-1.55.77-2.91,1.52-3.21,3.75a16.64,16.64,0,0,0-.09,6.09c.36,1.56,1.17,3,1.62,4.52,1.4,4.79-3.83,11.07-.4,15.2,2.21,2.67,6.64,1.5,9.72,3.1,5.05,2.63,5.61,11.61,2.37,15.61-1.42,1.76-3.18,3.38-3.82,5.55a6.23,6.23,0,0,1-.9,2.38,3.21,3.21,0,0,1-3.41.58A36.33,36.33,0,0,0,13,339.58C8,338.5,1.51,348.53,2,352.89a5.52,5.52,0,0,1-2,5.4l.05.62,47.19,19.88V332H33Z" fill={color1} />
      <path d="M131.12,34.75c0-7.36-5.94-13.57-13-13.57H89.84V48.12h28.3A13.19,13.19,0,0,0,131.12,34.75Z" fill={color1} />
      <path d="M235.75,49.12h5.32V.29L175.35.12v49h55.58A61,61,0,0,1,236.06,43C235.89,45,235.8,47,235.75,49.12Z" fill={color1} />
      <path d="M279.09,86.66a119.23,119.23,0,0,1,19.29,6.22l4.13-5.76a125.44,125.44,0,0,0-21.87-7.22,114.44,114.44,0,0,1-5-27.51c11.92.88,25.32,5.09,37,10.5l-7.72-10.78a110.8,110.8,0,0,0-29.38-6.67A96.12,96.12,0,0,1,278.13,25a57.25,57.25,0,0,1,7.79.56l-18-25.2h-4.29V26.79a56.85,56.85,0,0,1,7.34-1.38c-3.9,18-2.86,37,3.09,56.53a122.42,122.42,0,0,1-10.43,9.91v8.86A126,126,0,0,0,279.09,86.66ZM277.51,24.2Z" fill={color1} />
      <path d="M306.64,174.89l-1.71-2.31a32.68,32.68,0,0,0-26.54-13.32,33.24,33.24,0,0,0-26.79,53.05,7.23,7.23,0,0,0,.6-.85,5.09,5.09,0,0,0,.51-1.31l1.43-5.29q1.16-4.25,2.3-8.49l1.19-4.37a5.8,5.8,0,0,0,.19-1.66c0-1.42-.25-3.94-1.23-3.51-.49.22-2.82,1.81-2.82,1.81,0-.73.09-1.58.13-2.37a10.48,10.48,0,0,1,2.2-6,4.25,4.25,0,0,1,1.31-1.19,37.77,37.77,0,0,1,15-3.28c6.72-.23,16.4,1.63,19.54,3.74,5.05,3.38,8.43,4.08,8.43,4.08a49.29,49.29,0,0,1,4.79,1.76,3.46,3.46,0,0,1,1.77,2.31c0,.1.05.2.07.28a4.79,4.79,0,0,1,.11,1l0,2.27a17.44,17.44,0,0,1-.67,5l-.57,2c-.84-2.07-4.42-3.19-6.46-3.68a3.62,3.62,0,0,0-2.6.38l-1.55.9a1.05,1.05,0,0,0-.48,1.19l2.2,7.66a20.32,20.32,0,0,0,1.33,3.45l2,4a8.78,8.78,0,0,0,2.3,2.9l.18.11c.75-.82,1.49-1.66,2.17-2.56l1.73-2.27,3.78,2.8,8.77-2.8c.22-.08.43-.18.64-.28V165.2Z" fill={color1} />
      <path d="M280.86,182.72c-.18.07-1.4.57-.91.95a12.7,12.7,0,0,0,3,2c1.27.5,6-.4,6-.4l.13-.06a11.37,11.37,0,0,0-5.23-2.81A5.06,5.06,0,0,0,280.86,182.72Z" fill={color1} />
      <path d="M152.84,27.28V.07L125.39,0a35.79,35.79,0,0,1,27.45,27.28Z" fill={color1} />
      <path d="M322,98.2,318,103.82a94.92,94.92,0,0,1,9.89,8.17,58.14,58.14,0,0,1-35,26.69l-3.48,4.83a49.88,49.88,0,0,1,29.19,19l1.27,1.71V145.08H330.7a30.88,30.88,0,0,0-4.94-10.27.57.57,0,0,1,.15-.83c2.42-1.53,4.3-3.84,6.47-6.52l1-1.23a48.52,48.52,0,0,0,6.25-9.13,63.89,63.89,0,0,0,4.19-10.85l-9.07-12.67a57.31,57.31,0,0,1-3.64,12A100.67,100.67,0,0,0,322,98.2Z" fill={color1} />
      <polygon points="277.51 24.2 277.51 24.2 277.51 24.2 277.51 24.2" fill={color2} />
      <path d="M263.59,131.54v9.82l1.53-2.14.73-1,10.06-14A119.78,119.78,0,0,1,263.59,131.54Z" fill={color1} />
      <path d="M142.27,224.42h10.38a26.15,26.15,0,0,1-4.18-2.12l-1-.59a58.84,58.84,0,0,1-9.39-6.68.54.54,0,0,0-.7,0,.58.58,0,0,0-.15.71,94.77,94.77,0,0,0,4.88,8.51Z" fill={color2} />
      <path d="M319.87,220.12V210c-.21.1-.42.2-.64.28l-8.77,2.8Z" fill={color2} />
      <polygon points="189.5 198.1 209.18 198.1 199.38 172.45 189.5 198.1" fill={color2} />
      <path d="M345.4,65.48c.28,2,.55,4,.77,6l29.63,41.27a82.57,82.57,0,0,0,1.07-15c-.12-3.91-.53-8-3.14-11.13C369.1,81.2,359.08,71,359.08,71l-5.81-5.73a21.39,21.39,0,0,0-5.07-3.69c-.11-.06-.21-.13-.32-.18-1.31-.66-2.76-1.41-3.75-2,0,0,1.06,4.55,1.14,5.09C345.31,64.83,345.35,65.16,345.4,65.48Z" fill={color2} />
      <path d="M394.32,101.21c-.11.75-.21,1.47-.3,2.14l-.25,1.87c-.57,4.29-1,7.4-2,9.53-1.59,3.2-3.82,4.24-6.63,5.55-.71.32-1.44.66-2.18,1l-.13.06-.57.29,12.86,17.92c0-2,0-3.94,0-5.91q0-19.61.08-39.21C394.93,96.93,394.61,99.17,394.32,101.21Z" fill={color2} />
      <path d="M375,273.09c.53-.21,1.07-.4,1.62-.59a35.91,35.91,0,0,1,5.69-1.28c1.29-.22,2.62-.45,4-.76a42.73,42.73,0,0,0,8.6-2.84q0-8.46,0-16.9a19,19,0,0,1-4.19.36Z" fill={color2} />
      <path d="M391.8,277.58a57.56,57.56,0,0,1-11.17,2.53l-1.09.17q-6.35,1-9.09-.76l-6.43,9v3.08l.79.22a.53.53,0,0,0,.6-.22,2.28,2.28,0,0,1,.36,0,25.27,25.27,0,0,0,9.83-2.37l1.39-.61a41.25,41.25,0,0,0,7.69-4.08,90,90,0,0,0,7.65-6,.57.57,0,0,0,.13-.71A.55.55,0,0,0,391.8,277.58Z" fill={color2} />
      <path d="M287.71,261.22v11.9h36.14v16.33H287.71v26.37H318c0-1,0-1.93,0-2.65-.09-1.83-.2-3.58-.44-5.23,1.6.43,3.25.91,4.94,1.4l2.37.24c1.85-.06,3.71,0,5.55-.11s3.47-.21,5.18-.51a30.91,30.91,0,0,0,11.31-4,.57.57,0,0,0,.24-.66.54.54,0,0,0-.57-.39c-2.49.24-3.63-2.6-4.2-4.23l.25-.26a52.49,52.49,0,0,1,4.85-4.52v-6.32L328,261.22Z" fill={color2} />
      <path d="M271.26,245.23h48.61V220.81l-1.4,1.88a49.61,49.61,0,0,1-40.08,20.1A50.33,50.33,0,0,1,236,219.48l7.72,20v76.49h27.57Z" fill={color2} />
      <path d="M229.93,134c-2.42-1.53-4.3-3.84-6.47-6.52l-1-1.23a48.49,48.49,0,0,1-6.24-9.13c-6.81-13.61-8.24-29.87-6.64-44.82,0-.41.1-.82.14-1.23.26-2.18.54-4.36.86-6.54.08-.54,1.14-5.09,1.14-5.09-.84.47-2,1.08-3.15,1.65a26.28,26.28,0,0,0-6.52,4.7L196.76,71l-.07.07c-.89.91-10.17,10.41-14.58,15.6-2.61,3.08-3,7.22-3.14,11.13a92.41,92.41,0,0,0,1.83,19.49l.67,3.47c.15.74.29,1.48.43,2.22l.27,1.4c0,.09.11.31.08.4-.09.28-.85.52-1.12.65a27.37,27.37,0,0,0-4.1-2.2c-.56-.23-1.13-.47-1.68-.72-.84-.37-1.65-.74-2.3-1.08l-.13-.06c-.75-.39-1.47-.73-2.18-1-2.82-1.31-5-2.35-6.64-5.55-1.06-2.13-1.46-5.24-2-9.53l-.25-1.87c-.09-.67-.19-1.39-.3-2.14-.42-2.91-.9-6.2-1.1-9.91a.57.57,0,0,0-.41-.52.55.55,0,0,0-.6.24,48.38,48.38,0,0,0-3.73,7.71c-.44,1.08-.86,2.11-1.3,3.06-.53,1.14-1,2.31-1.54,3.48a40.18,40.18,0,0,0-2.4,7.3c-1.37,6.67-.33,13,2.26,19.27.05.11.09.23.14.34,2.78,7,6.27,23.73,6.27,23.73l-12.85-10.83h0c-.4-.79-.83-1.59-1.27-2.42a50.31,50.31,0,0,1-3.12-6.54.56.56,0,0,0-.51-.36.54.54,0,0,0-.51.36,50.55,50.55,0,0,0-2.85,9c-.09.52-.17,1-.21,1.53a24.52,24.52,0,0,0,1.61,10.95,28.29,28.29,0,0,0,2.09,4.24c3,5.08,7.47,9.38,11.52,13.7.55.58,1.06,1.16,1.56,1.75a53.6,53.6,0,0,1,5.15,7A65.11,65.11,0,0,1,165.33,196c-.67-.14-1.34-.28-2-.39a29.4,29.4,0,0,1-4.73-1.25h-26c0,.1.06.21.1.31a32,32,0,0,0,5.43,9,41.34,41.34,0,0,0,7.72,6.89c1.13.81,2.27,1.54,3.38,2.24a36.59,36.59,0,0,1,4.8,3.4c2.38,2.11,4.59,4.28,5.57,7.59a4.37,4.37,0,0,1-.69.61h2l30.53-79.34h15.8l21.56,55.83-.09-.62a173.56,173.56,0,0,1-5-25.1c-1.42-12.35-1.66-29.92,6.35-40.38a.65.65,0,0,0,.11-.45A.57.57,0,0,0,229.93,134Z" fill={color2} />
      <path d="M133.5,177.12c-.73-1.33-1.49-2.69-2.32-4a.55.55,0,0,0-1,.31c0,1.27.07,2.54.18,3.79h3.23Z" fill={color2} />
      <path d="M182.83,215.08l-8.41,22V248.5c.28.61.56,1.26.83,2l-.83,0-1.93.11-2.16.11c-.62,0-1.25.09-1.89.14a24.59,24.59,0,0,1-7.9-.25c-.68-.16-1.38-.3-2.1-.45s-1.52-.32-2.31-.51a23.84,23.84,0,0,1-5.26-1.82,31.46,31.46,0,0,1-5.07-3.58c-1.17-.94-2.38-1.91-3.62-2.77a.53.53,0,0,0-.66,0,.57.57,0,0,0-.16.64,60.25,60.25,0,0,0,4.13,8.11c.31.52.6,1,.87,1.51.65,1.15,1.21,2.31,1.76,3.43a22.27,22.27,0,0,0,3.37,5.61,28.9,28.9,0,0,0,4.64,4,31.81,31.81,0,0,0,3.72,2.28,40.18,40.18,0,0,0,9.68,3.38c1.34.31,2.67.54,4,.76l.93.16a30.37,30.37,0,0,1,4.76,1.12c3,1,5.83,2.17,7.95,4.86-1,2.88-4.66,3.86-10.83,2.92l-1.1-.17-.78-.12A55.24,55.24,0,0,1,164,277.58a.54.54,0,0,0-.65.25.58.58,0,0,0,.12.71,91.47,91.47,0,0,0,7.66,6,31.9,31.9,0,0,0,3.26,2c1.56.83,3.05,1.48,4.43,2.08l1.39.61a25.27,25.27,0,0,0,9.82,2.37,2.4,2.4,0,0,1,.37,0,.53.53,0,0,0,.6.22c.27-.09.53-.15.8-.22a56.7,56.7,0,0,0,8.77-.56,14.86,14.86,0,0,1,2.81.81c4,1.61,7.24,4.88,9.82,7.51l.25.26c-.57,1.63-1.71,4.47-4.2,4.23a.53.53,0,0,0-.57.39.57.57,0,0,0,.24.66,30.91,30.91,0,0,0,11.31,4c1.7.3,3.42.37,5.13.5V240.11l-9.55-25Z" fill={color2} />
      <path d="M307,334a19.35,19.35,0,0,0-.76-1.93H249.24a14.84,14.84,0,0,0-.63,1.77,19.9,19.9,0,0,0,.73,12.07c1,2.74,2.84,5,5.37,8.09l.84,1a.52.52,0,0,0,.54.19.54.54,0,0,0,.41-.41c.93-3.68,3.17-4,5.54-4.38a12.33,12.33,0,0,0,2.21-.47c-.67,3.5-1.27,7.39,0,11.33,1,3.25,4.53,7.16,9,10.17-1-3.77-.39-5.9.78-7.11,2-2,5.76-2.63,7.26-1.6,1.74,1.2,2.19,2.14,1.37,6.17a36.77,36.77,0,0,1-1.58,5.14c-.22.61-1.28,2.44-1.11,3,0,0,1.42-.61,1.59-.71.6-.35,1.09-.85,1.66-1.25,1.57-1.11,3.45-1.68,5-2.87a16,16,0,0,0,3-3.11c.23-.33.45-.66.66-1s.46-.79.67-1.2a20.77,20.77,0,0,0,2.07-10.34,19.27,19.27,0,0,0-.74-2.88c-.3-1.06-.65-2.23-.89-3.26.05.21,1.79.09,2,.09a2.75,2.75,0,0,1,2.63,1.21,8,8,0,0,1,1.3,2.91,8.58,8.58,0,0,0,.42,1.14c1.36.38,3.56-3,4.09-3.81a32.84,32.84,0,0,0,3.16-6.05,18.66,18.66,0,0,0,1.08-3.94,16.52,16.52,0,0,0,0-5.39A17.76,17.76,0,0,0,307,334Z" fill={color2} />
      <path d="M295.27,195.81l1.55-.9a3.62,3.62,0,0,1,2.6-.38c2,.49,5.62,1.61,6.46,3.68l.57-2a17.44,17.44,0,0,0,.67-5l0-2.27a4.79,4.79,0,0,0-.11-1c0-.08,0-.18-.07-.28a3.46,3.46,0,0,0-1.77-2.31,49.29,49.29,0,0,0-4.79-1.76s-3.38-.7-8.43-4.08c-3.14-2.11-12.82-4-19.54-3.74a37.77,37.77,0,0,0-15,3.28,4.25,4.25,0,0,0-1.31,1.19,10.48,10.48,0,0,0-2.2,6c0,.79-.09,1.64-.13,2.37,0,0,2.33-1.59,2.82-1.81,1-.43,1.19,2.09,1.23,3.51a5.8,5.8,0,0,1-.19,1.66l-1.19,4.37q-1.14,4.24-2.3,8.49l-1.43,5.29a5.09,5.09,0,0,1-.51,1.31,7.23,7.23,0,0,1-.6.85,33.27,33.27,0,0,0,51.18,2.84l-.18-.11a8.78,8.78,0,0,1-2.3-2.9l-2-4a20.32,20.32,0,0,1-1.33-3.45l-2.2-7.66A1.05,1.05,0,0,1,295.27,195.81ZM289,185.25s-4.76.9-6,.4a12.7,12.7,0,0,1-3-2c-.49-.38.73-.88.91-.95a5.06,5.06,0,0,1,3-.34,11.37,11.37,0,0,1,5.23,2.81Z" fill={color2} />
      <path d="M332.38,127.46c-2.17,2.68-4.05,5-6.47,6.52a.57.57,0,0,0-.15.83,30.88,30.88,0,0,1,4.94,10.27h6.4v32.3h40.62V153.56l-33.9-47.31a63.89,63.89,0,0,1-4.19,10.85,48.52,48.52,0,0,1-6.25,9.13Z" fill={color2} />
      <polygon points="377.72 194.22 337.1 194.22 337.1 244.89 355.8 271.86 377.72 241.23 377.72 194.22" fill={color2} />
      <path d="M241.07,127.34a57.79,57.79,0,0,0,13.19,8.26,73.91,73.91,0,0,0,9.33-4.06,119.78,119.78,0,0,0,12.32-7.35l22.47-31.31a119.23,119.23,0,0,0-19.29-6.22,126,126,0,0,1-15.5,14A107.94,107.94,0,0,1,241.07,114q-4.62,2-9.41,3.53A58.42,58.42,0,0,0,241.07,127.34Z" fill={color2} />
      <path d="M241.07,106.37q2.85-1.34,5.63-2.86a94.82,94.82,0,0,1-5.63-14.77,131.68,131.68,0,0,1-3.88-17.69,130.09,130.09,0,0,1-1.44-21.93c.05-2.09.14-4.16.31-6.17a61,61,0,0,0-5.13,6.17,57.21,57.21,0,0,0-9.65,21.93,59,59,0,0,0-1.2,11.78,57.68,57.68,0,0,0,7.62,28.66A95.57,95.57,0,0,0,241.07,106.37Z" fill={color2} />
      <path d="M263.59,91.85A122.42,122.42,0,0,0,274,81.94c-5.95-19.51-7-38.52-3.09-56.53a56.85,56.85,0,0,0-7.34,1.38A57.74,57.74,0,0,0,244,36c-3.05,16-.59,44.66,8.7,63.92A109.55,109.55,0,0,0,263.59,91.85Z" fill={color2} />
      <path d="M285.92,25.55a57.25,57.25,0,0,0-7.79-.56,96.12,96.12,0,0,0-2.57,20.45,110.8,110.8,0,0,1,29.38,6.67Z" fill={color2} />
      <path d="M327.85,112a94.92,94.92,0,0,0-9.89-8.17l-25.07,34.86A58.14,58.14,0,0,0,327.85,112Z" fill={color2} />
      <path d="M280.64,79.9a125.44,125.44,0,0,1,21.87,7.22l13.76-19.18-3.61-5c-11.66-5.41-25.06-9.62-37-10.5A114.44,114.44,0,0,0,280.64,79.9Z" fill={color2} />
      <path d="M334.75,93.58,330.05,87,322,98.2a100.67,100.67,0,0,1,9.1,7.34A57.31,57.31,0,0,0,334.75,93.58Z" fill={color2} />
    </svg>
  );
};

export default AZ3;